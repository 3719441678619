/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.post {
  padding: 12px;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

.mat-drawer-inner-container::-webkit-scrollbar {
  width: 5px;
}

.mat-drawer-inner-container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 3px solid white;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #8d241f;
}

.snackbar-success {
  background-color: white;
  color: #4e9f4e;
}

.snackbar-success .mat-button-wrapper {
  color: #4e9f4e !important;
}

.snackbar-error {
  background-color: white;
  color: #8d241f;
}

.snackbar-error .mat-button-wrapper {
  color: #8d241f !important;
}
